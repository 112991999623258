<template>
  <div class="card">
    <h1>{{ this.piece.name }}</h1>

    <!-- 
        <hr />

        Cantidad producidas: {{ this.piece.produced }}
        
        <hr />
        Ventas realizadas: {{ this.piece.selled }}
        
        <hr /> -->

    <div>
      <DataTable :value="piece.history" responsiveLayout="scroll">
        <Column field="created_at" header="Fecha"></Column>
        <Column field="oc" header="OC"></Column>
        <Column field="to_name" header="Para"></Column>
        <Column field="mp_to_use" header="M.P."></Column>
        <Column field="qty" header="Cantidad"></Column>
        <Column field="last_price" header="Valor">
          <template #body="slotProps">
            {{ toArs(slotProps.data.last_price) }}
          </template>
        </Column>
        <Column field="dolar_value" header="Valor Dolar"></Column>
      </DataTable>

      <hr />
    </div>
  </div>
</template>

<script>
import CrudService from "../../services/crud.service.js";

export default {
    props: ["piece_id"],
  data() {
    return {
        testData: {},
      piece: {
        name: "",
        produced: 54,
        selled: 12,
        description: "",
        image: "",
        price: "",
        category: "",
        created_at: "",
        updated_at: "",
        history: [],
      },
    };
  },
  mounted() {
    this.getPiece();
  },
  methods: {
    toArs(value) {
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    },
    getPiece() {

        if(this.$route.params.id){
            CrudService.getCRUD("api/pieza/" + this.$route.params.id).then(
        (response) => {
          //this.piece = response;
          // merge response with piece

          this.piece = Object.assign(this.piece, response);
        }
      );
        }

        if(this.piece_id){
            CrudService.getCRUD("api/pieza/" + this.piece_id).then(
        (response) => {
            //this.piece = response;
            // merge response with piece
    
            this.piece = Object.assign(this.piece, response);
            }   
        );
        }


    },
  },
};
</script>