<template>
    <div class="grid" v-if="this.loaded">
      <div class="col-12">
        <div class="card">
          <Toast>
            <template #message="slotProps">
              <span :class="iconClass"></span>
              <div class="p-toast-message-text">
                <span
                  class="p-toast-summary"
                  v-html="slotProps.message.summary"
                ></span>
              </div>
            </template>
          </Toast>
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-1">
                <div class="col-md-12 section_title">
                    EDITAR COTIZACIÓN # {{ this.form.id }}

                </div>
              </div>
            </template>
  
            <template v-slot:end>
              <Button
                label="ACTUALIZAR"
                icon="pi pi-save"
                class="p-button-success mr-2"
                @click="save()"
              />
            </template>
          </Toolbar>
          <div class="">
            <div class="formgrid grid">
              <div class="field col-4">
                <label for="base">Cliente</label>
                <AutoComplete
                  v-model="selectedClient"
                  :suggestions="filteredClients"
                  @complete="searchClient($event)"
                  field="name"
                  :dropdown="true"
                  @item-select="newClient = selectedClient"
                  forceSelection="true"
                  id="base"
                  class="inputfield w-full"
                />
  
                <Button
                  :disabled="selectedClient"
                  @click="newClient()"
                  label="Nuevo Cliente"
                  icon="pi pi-plus"
                  class="mt-1 inputfield p-button-sm p-button-info"
                />
              </div>
              <div class="field col-2">
                <label for="entrega"> Entrega  </label>
                <Calendar
                  id="entrega"
                  v-model="form.entrega"
                  :disabled="!newClient"
                  class="inputfield w-full"
                />
              </div>
  
              
              <div class="field col-2">
                <label for="vencimiento"> Validez </label>
                <InputNumber
                  id="vencimiento"
                  v-model="form.validez"
                  :disabled="!newClient"
                    class="inputfield w-full"
                />
              </div>
              <div class="w-1  ">
                
              </div>  
              <div class="field col-2">
                  <label for="p_dolar">Precio U$D</label>
                  <InputNumber
                    mode="decimal"
                    :minFractionDigits="2"
                    v-model="this.p_dolar"
                    id="p_dolar"
                    
                    class="inputfield w-full"
                  />
                  <div>
                    <small> BNA: {{ this.dolar_suggested }}</small>
                  </div>
                </div>
              <div class="w-1  ">
                
                </div>  
              <div class="field col-2">
                <label for="formu">Moneda</label>
                <SelectButton
                  v-model="form.moneda"
                  :options="[
                    { value: 0, name: 'AR$' },
                    { value: 1, name: 'U$D' },
                  ]"
                  optionLabel="name"
                    class="inputfield w-full"
                />
  
              </div>
              <div class="field col-4">
                <label for="impuesto">Impuesto</label>
                <SelectButton
                  v-model="form.impuesto"
                  :options="impuestos"
                  optionLabel="name"
                  class="inputfield "
                />
              </div>
  
              
              <div class="field col-3">
                <label for="fdp">Forma de pago</label>
                <InputText
                  id="formu"
                  v-model="selectedFdp"
                  :disabled="!newClient"
                  class="inputfield w-full"
                />
                <!-- <Dropdown id="fdp" v-model="selectedFdp" :options="formasdepago" optionLabel="name" placeholder="Seleccione una" /> -->
              </div>
  
  
              <div class=" field  col-3" >
                <label for="descuento">Descuento</label>
                <InputNumber
                  id="descuento"
                  v-model="form.descuento"
                  :disabled="!newClient"
                  class="inputfield w-full"
                />
              </div>
  
              <div class="field col-md-12 col-12">
                <label for="formu">Observación</label>
                <InputText
                  id="formu"
                  v-model="form.observacion"
                  :disabled="!newClient"
                  class="inputfield w-full"
                />
              </div>
            </div>
  
            <div class="formgrid grid">
  
  <div class="field col-4">
        <label for="formu">Envío</label>
        <SelectButton
          v-model="form.envio"
          :options="fde"
          optionLabel="name"
        />
      </div>
  
  
      
  
  <!-- <h4>Materia prima a utilizar</h4>
  <div class="field-radiobutton">
    <RadioButton
      id="city1"
      name="city"
      :value="0"
      v-model="form.mat_a_cargo"
    />
    <label for="city1">FERRARO</label>
  </div>
  <div class="field-radiobutton">
    <RadioButton
      id="city2"
      name="city"
      value="CLIENTE"
      v-model="form.mat_a_cargo"
    />
    <label for="city2">CLIENTE</label>
  </div> -->
  
  <div class="field col-12">
    <InputText
      id="formu"
      v-model="form.mat_a_cargo_obs"
      :disabled="!form.envio"
      class="inputfield w-full"
      placeholder="Observaciones del envío"
    />
  </div>
  </div>
          </div>
        </div>
      </div>
      
      <div class="col-12">
        <div class="card">
          <div class="my-1">
            <div class="col-md-12 section_subtitle">Items <small> </small></div>
          </div>
  
          <div class="">
            <div class="formgrid grid">
              <div class="field col-3">
                <label for="base">Producto</label>
                <AutoComplete
                  v-model="selectedProduct"
                  :suggestions="filteredProducts"
                  @complete="searchProduct($event)"
                  field="name"
                  :dropdown="true"
                  :value="name"
                  @item-select="selectedItem(selectedProduct)"
                  @item-unselect="newItem = null"
                  id="base"
                  class="inputfield w-full"
                >
                  <template #item="slotProps">
                    <strong>
                      {{ slotProps.item.code }}
                    </strong>
                    - {{ slotProps.item.name }}
                  </template>
                </AutoComplete>
                <Button
                  :disabled="selectedProduct && selectedProduct.id"
                  @click="createPiece(selectedProduct)"
                  icon="pi pi-plus"
                  label="Nueva pieza"
                  class="p-button-sm p-button-info mt-1"
                />
              </div>
              <div class="field col-1">
                <label for="formu">Cantidad</label>
                <InputText
                  :disabled="!selectedProduct"
                  v-model="qtyToAdd"
                  id="formu"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
              </div>
              <div class="field col-3">
                <label for="formu">Materia Prima</label>
                <!-- <InputText
                  :disabled="!selectedProduct"
                  v-model="mpnameToAdd"
                  id="formu"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                /> -->
                <AutoComplete class="inputfield w-full" v-model="mpnameToAdd" :suggestions="filteredMPNames" @complete="searchMPNames($event)"  >
    </AutoComplete>
  
              </div>
              <div class="field col-2">
                <label for="formu w-full">Valor</label>
                <InputText
                  :disabled="!selectedProduct"
                  v-model="valorToAdd"
                  id="formu"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
              </div>
              <div class="field col-2">
                <div
                  class="relative w-3rem h-4rem mx-3 my-3 md:my-0 border-round"
                >
                  <div
                    class="
                      absolute
                      bottom-0
                      left-0
                      text-white
                      font-bold
                      flex
                      align-items-center
                      justify-content-center
                      border-round
                    "
                  >
                    <Button
                      v-if="selectedProduct && selectedProduct.id"
                      @click="editPiece(selectedProduct)"
                      icon="pi pi-pencil"
                      class="p-button-sm p-button-info"
                    />
  
                    <Button
                      v-if="selectedProduct && selectedProduct.id"
                      @click="showPriceHistory(selectedProduct)"
                      icon="pi pi-dollar"
                      class="p-button-sm p-button-secondary ml-1"
                    />
  
                    <Button
                  :disabled="
                    !qtyToAdd || (selectedProduct && !selectedProduct.id) || (selectedProduct && !selectedProduct.formula )
                  "
                  @click="addItem(newItem)"
                  label="Agregar"
                  icon="pi pi-plus"
                  class="p-button-success inputfield w-full ml-2"
                />
              </div>
            </div>
            <Message severity="warn" v-if="selectedProduct && !selectedProduct.formula"> Configure los costos de la pieza antes de agregarla a la cotización.</Message>
          </div>
  
            </div>
          </div>
  
          <div class="">
            <DataTable
              :value="items"
              dataKey="id"
              class="p-datatable-sm"
              responsiveLayout="scroll"
            >
              <Column header="" headerStyle="width: 10%">
                <template #body="slotProps">
                  <div class="flex">
                    <Button
                      icon="pi pi-times"
                      class="p-button-rounded p-button-danger mr-1"
                      @click="delItem(slotProps.data)"
                    ></Button>
                    <a
                      v-if="slotProps.data.plane"
                      :href="
                        this.appBackend + '/uploads/' + slotProps.data.plane.file
                      "
                      target="_blank"
                    >
                      <Button
                        icon="pi pi-file-pdf"
                        class="p-button-rounded p-button-info"
                      ></Button>
                    </a>
                    <Button
                      icon="pi pi-pencil"
                      class="p-button-rounded p-button-success ml-1 mr-1"
                      @click="editPiece(slotProps.data)"
                    ></Button>
                  </div>
                </template>
              </Column>
  
              <Column
                headerClass=""
                field="qty"
                header="Cantidad"
                headerStyle="width: 10%"
              >
                <template #body="slotProps">
                  <InputText
                    v-model="slotProps.data.qty"
                    id="iqty"
                    type="text"
                    class="inputfield w-full"
                    placeholder=""
                  />
                </template>
              </Column>
              <Column field="code" header="Código"></Column>
              <Column field="name" header="Detalle"></Column>
              <Column field="plane_number" header="Plano N°">
                <!-- <template #body="slotProps">
                                        {{ slotProps.data }}
                  <InputText
                    v-model="slotProps.data.plane_number"
                    class="inputfield w-full"
                  />
                </template> -->
              </Column>
              <Column field="plane_rev" header="Rev">
                <!-- <template #body="slotProps">
                  <InputText
                    v-model="slotProps.data.plane_rev"
                    class="inputfield w-full"
                  />
                </template> -->
              </Column>
  
              <Column field="plane_number" header="Materia Prima">
                <template #body="slotProps">
                  <!-- <InputText
                    v-model="slotProps.data.mp"
                    class="inputfield w-full"
                  /> -->
                  <AutoComplete v-model="slotProps.data.mp" :suggestions="filteredMPNames" @complete="searchMPNames($event)"  >
    </AutoComplete>
                  <div class="field-checkbox">
              <Checkbox :id="'mpc' + slotProps.index" :inputId="'mpc' + slotProps.index" v-model="slotProps.data.mpc" :binary="true" />
              <label :for="'mpc' + slotProps.index"> A cargo del cliente  ( {{ slotProps.data.matCost }} )</label>
          </div>
                </template>
              </Column>
  
              <Column field="valor" header="Valor">
                <template #body="slotProps">
                  <div class="flex justify-content-between">
                    <div v-if="!slotProps.data.mpc">
                      <span>{{ toSelectedValue(Number(slotProps.data.valor)) }}</span>
                    </div>
                    <div v-else>
                      <span>{{ toSelectedValue(Number(slotProps.data.valor) - Number(slotProps.data.matCost) ) }}</span>
                    </div>
  
                    
                  </div>
                </template>
              </Column>
              <!-- <Column field="unidad" header="Unidad"></Column> -->
              <Column header="Subtotal">
                <template #body="slotProps">
                  <div class="flex justify-content-between">
                    <div>
  
  
  <div v-if="!slotProps.data.mpc">
                      <span>{{ toSelectedValue(Number(slotProps.data.valor)  * Number(slotProps.data.qty)) }}</span>
                    </div>
                    <div v-else>
                      
                      <span>{{ toSelectedValue((Number(slotProps.data.valor) ) * (Number(slotProps.data.qty)) - (Number(slotProps.data.matCost) * Number(slotProps.data.qty) ))  }}</span>
                    </div>
  
                    </div>
                  </div>
                </template>
                ></Column
              >
            </DataTable>
          </div>
        </div>
        
        <div class="card">
          <div class="mx-1">
            <div class="col-4 section_subtitle p-ml-auto">
              Totales <small> </small>
            </div>
          </div>
          <div v-if="calculos">
            
          <DataTable
            :value="calculos"
            dataKey="id"
            class="p-datatable-sm"
            responsiveLayout="scroll"
          >
            <Column field="name" header="Detalle"></Column>
            <Column field="valor" exportableheader="Subtotal">
              <template #body="slotProps">
                <div class="flex justify-content-between">
                  <div>
                    <span>{{ toSelectedValue(slotProps.data.valor) }}</span>
                  </div>
                </div>
              </template>
              ></Column
            >
          </DataTable>

          
        </div>
        </div>
      </div>
  
      <Dialog
        v-model:visible="showModalNewClient"
        :style="{ width: '50%' }"
        header="Nuevo cliente"
        :modal="true"
        class="p-fluid"
      >
        <ModalNewClient @add_client="add_client" />
      </Dialog>
  
      <Dialog
        v-model:visible="showModalEdit"
        :style="{ width: '75%' }"
        header="Editar pieza"
        :modal="true"
        class="p-fluid"
      >
        <PiezaEdit
          @update_piece="update_piece"
          :piece_id="selectedProduct.id"
          :modal_mode="'edit'"
        />
      </Dialog>
  
      <Dialog
        v-model:visible="showModalPriceHistory"
        :style="{ width: '50%' }"
        header="Historial de precios"
        :modal="true"
        class="p-fluid"
      >
        <PiezaShow :piece_id="selectedProduct.id" />
      </Dialog>
  
      <Dialog
        v-model:visible="showModalCreate"
        :style="{ width: '75%' }"
        header="Crear pieza"
        :modal="true"
        class="p-fluid"
      >
        <PiezaCreate
          @create_piece="create_piece"
          :modal_mode="'create'"
          :prodname="selectedProduct"
        />
      </Dialog>
    </div>
  </template>
  
  <script>
  import CrudService from "./../../services/crud.service.js";
  import PiezaEdit from "./../piezas/edit.vue";
  import PiezaCreate from "./../piezas/create.vue";
  import PiezaShow from "./../piezas/show.vue";
  import ModalNewClient from "./../clients/new_modal.vue";
  
  export default {
    components: {
      PiezaEdit,
      PiezaCreate,
      ModalNewClient,
      PiezaShow,
    },
    data() {
      return {
        loaded: false,
        p_dolar: 1,
        dolar_suggested: 1,
        files: null,
        showModalEdit: false,
        showModalCreate: false,
        showModalNewClient: false,
        routeName: null,
        clientType: { value: 0, name: "Consumidor Final" },
        configuraciones: [],
        qtyToAdd: 1,
        valorToAdd: 1,
        selectedFdp: null,
        Clients: null,
      filteredClients: null,
      selectedClient: null,
        form: {
          validez: 15,
          mat_a_cargo: 0,
          impuesto: { value: 0.21, name: "21%" },
          envio: { value: 0, name: "Si" },
          moneda: { value: 0, name: "AR$" },
          observacion: " PRUEBA ",
        },
        impuestos: [
                    { value: 0, name: '0%' },
                    { value: 0.21, name: '21%' },
                    { value: 0.105, name: '10.5%' },
                  ],
                  fde: [
            { value: 0, name: 'Si' },
            { value: 1, name: 'No' },
          ],
        items: [],
        actualFormula: "",
        products: null,
        filteredProducts: null,
        newItem: null,
        selectedProduct: null,
  
        Clients: null,
        filteredClients: null,
        newItem: null,
        selectedClient: null,
  
        mods: null,
        newItemMOD: null,
        filteredMod: null,
        selectedMod: null,
        actualFormulaMOD: "",
  
        modsMarco: null,
        newItemMODMarco: null,
        filteredModMarco: null,
        selectedModMarco: null,
        actualFormulaMODMarco: "",
  
        submitted: false,
        validationErrors: null,
  
        showModalPriceHistory: false,
  
        mpnameToAdd: "",
        ciif: 0,
        mpi: 0,
        moi: 0,
        formasdepago: [],
        materials: [],
  
        mpnames: [],
        filteredMPNames: null,
        selectedMPName: null,
  
      };
    },
    computed: {
      dolarValue() {
        switch (this.form.moneda.value) {
          case 1:
            return this.configuraciones.dolar_billete;
          case 3:
            return this.configuraciones.dolar_divisa;
          default:
            return this.configuraciones.dolar_billete;
        }
      },
      totalItems() {
        let total = 0;
  
        this.items.forEach((item) => {
          //console.log(item);
          if(item.mpc){
            total += (item.valor - item.matCost) * item.qty;
          }else{
            total += item.valor * item.qty;
          }
        });
  
        return total;
      },
      totalMOD() {
        let total = 0;
  
        this.mod.forEach((item) => {
          //console.log(item);
          total += item.valor * item.qty;
        });
  
        return total;
      },
      calculos() {
        // Costo de Produccion
        // MPI
        // MOI
        // CIIF
        // Costo de General
  
        let data = [
          {
            name: "Subtotal",
            valor: this.totalItems,
          },
          /* {
            name: "IVA",
            valor: this.mpi
          },
          {
            name: "Otros Impuestos",
            valor: this.moi
          },
          {
            name: "Total",
            valor: this.ciif
          },*/
          // {
          //   name: "Costo de General",
          //   valor:  this.totalMPD + this.totalMOD + this.mpi + this.moi + this.ciif
          // },
        ];
  
        if (this.form.descuento > 0) {
          // to negative
          data.push({
            name: "Descuento",
            valor: this.totalItems * (-this.form.descuento / 100),
          });
        }
  
        // check if items has mpc
        let hasMPC = false;
        let mpctotal = 0;
        this.items.forEach((item) => {
          if (item.mpc > 0) {
            hasMPC = true;
            mpctotal += item.matCost * item.qty;
          }
        });
  
        console.log(mpctotal)
  
        if (this.form.impuesto.value > 0) {
          let descuento = 0;
          if (this.form.descuento > 0) {
            descuento = this.totalItems * (-this.form.descuento / 100);
          }
  
          data.push({
            name: "Impuesto",
            valor: (this.totalItems + descuento) * this.form.impuesto.value,
          });
        }
  
        // sum all data valor
        let total = 0;
        data.forEach((item) => {
          total += item.valor;
        });
  
        data.push({
          name: "Total",
          valor: total,
        });
        return data;
      },
    },
    created() {
      // get route name
      this.routeName = this.$route.name;
    },
    mounted() {

      // get route url id
      let id = this.$route.params.id;

      // axios get data

      CrudService.getCRUD("api/cotizacion/json/"+ id).then((data) => {

        this.form.id = id
        this.selectedClient = data.client

        this.form.descuento = data.descuento
        this.form.observacion = data.obs

        this.p_dolar = data.dolar_bna

       this.selectedFdp = data.forma_pago

       // find impuesto with value
        this.form.impuesto = this.impuestos.find((impuesto) => {
          return impuesto.value == data.impuesto;
        });

        this.form.envio = this.fde.find((fde) => {
          return fde.value == Number(data.envio);
        });
        


      });

      CrudService.getCRUD("api/clients").then((data) => (this.clients = data));
  
      CrudService.getCRUD("api/pieza").then((data) => (this.products = data));
  
      CrudService.getCRUD("api/mpnames").then((data) => (this.mpnames = data));
  
      CrudService.getCRUD("api/costos")
        .then((data) => {
          //console.log(data.bna.dolar.value)
          this.dolar_suggested = parseInt(data.bna.dolar.value).toFixed(2);
  
          this.p_dolar = parseFloat(data.bna.dolar.value);
        })
        .then(() => {
          this.loaded = true;
        });
  
      // CrudService.getCRUD("configuraciones").then(response => {
      //     this.configuraciones = response[0]
  
      //     //this.dolarValue = this.configuraciones.dolarvalue
      //     this.mpi = this.configuraciones.mpi
      //     this.moi = this.configuraciones.moi
      //     this.ciif = this.configuraciones.ciif
      // });
  
      // CrudService.getCRUD("configuraciones/configuraciones", "formasdepago").then(response => {
      //     this.formasdepago = response
      // });
    },
    methods: {
      mpCost(data){
  
        console.log(data)
  
      },
      showPlane(file) {
        // open window
        window.open(file, "_blank");
      },
      selectedItem(selectedProduct) {
        this.newItem = null;
        this.valorToAdd = selectedProduct.last_price;
        if (selectedProduct.formula) {
          this.mpnameToAdd = selectedProduct.formula.material_name;
        }
      },
      update_piece(item) {
        this.selectedProduct = item.data;
        this.valorToAdd = item.data.last_price;
        this.mpnameToAdd = item.data.formula.material_name;
        this.showModalEdit = false;
  
        // update in items
        this.items.forEach((item) => {
          if (item.id == this.selectedProduct.id) {
            item.valor = this.valorToAdd;
            //item.mpname = this.mpnameToAdd;
            item.planes = this.selectedProduct.planos;
          }
        });
  
        // update in products
        this.products.forEach((item) => {
          if (item.id == this.selectedProduct.id) {
            item.last_price = this.valorToAdd;
          }
        });
      },
      newClient() {
        this.selectedClient = null;
        this.showModalNewClient = true;
      },
      add_client(data) {
        //console.log(data)
        this.clients.push(data.cliente);
        this.selectedClient = data.cliente;
        this.showModalNewClient = false;
      },
      create_piece(item) {
        // push to products
        this.products.push(item.data);
  
        this.selectedProduct = item.data;
        this.valorToAdd = item.data.last_price;
        this.mpnameToAdd = item.data.formula.material_name;
        this.showModalCreate = false;
      },
      showEditModal(item) {
        this.selectedProduct = item;
        this.showModalEdit = true;
      },
      showCreateModal(item) {
        this.selectedProduct = item;
        this.showModalCreate = true;
      },
      showPriceHistory(item) {
        this.selectedProduct = item;
        this.showModalPriceHistory = true;
      },
      editPiece(piece) {
        // this.$router.push({
        //   name: "edit-piece",
        //   params: {
        //     id: piece
        //   }
        // });
  
        this.showEditModal(piece);
      },
      createPiece(piece) {
        // this.$router.push({
        //   name: "edit-piece",
        //   params: {
        //     id: piece
        //   }
        // });
  
        this.showCreateModal(piece);
      },
      valorByClientType(valor) {
        if (this.clientType.value == 0) {
          return valor;
        } else {
          return valor * 0.3 + valor;
        }
      },
  
      costoProd() {
        let costo = 0;
  
        return costo;
      },
      toArs(value) {
        return value.toLocaleString("es-AR", {
          style: "currency",
          currency: "ARS",
        });
      },
      toUsd(value) {
        // format number to currency
        value = value / this.p_dolar;
        return value.toLocaleString("es-AR", {
          style: "currency",
          currency: "USD",
        });
      },
      toSelectedValue(value) {
        if (this.form.moneda.value == 0) {
          return this.toArs(value);
        } else {
          return this.toUsd(value);
        }
      },
      delItem(itemId) {
        //console.log(item)
        // find in this.items
        let index = this.items.findIndex((item) => item.id == itemId);
        // remove item
        this.items.splice(index, 1);
  
        // recalculate total
      },
      formatUnity(value) {
        switch (value) {
          case 0:
            return "ml";
          case 1:
            return "unidad";
          case 2:
            return "kg";
          case 3:
            return "mt";
          case 4:
            return "lt";
          case 5:
            return "m2";
          case 6:
            return "mtl";
          case 7:
            return "gr";
          case 8:
            return "juego";
          default:
            return "";
        }
      },
      displayErrors(errors) {
        for (let key in errors) {
          this.validationErrors.push({ field: key, message: errors[key] });
  
          let error = errors[key];
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error[0],
            life: 3000,
          });
        }
      },
      searchProduct(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredProducts = [...this.products];
          } else {
            this.filteredProducts = this.products.filter((prod) => {
              return (
                prod.name.toLowerCase().startsWith(event.query.toLowerCase()) ||
                prod.code.startsWith(event.query)
              );
            });
          }
        }, 250);
      },
      searchMPNames(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredMPNames = [...this.mpnames];
          } else {
            this.filteredMPNames = this.mpnames.filter((prod) => {
              return prod.toLowerCase().startsWith(event.query.toLowerCase())  
            });
          }
        }, 250);
      },
      searchClient(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredClients = [...this.clients];
          } else {
            this.filteredClients = this.clients.filter((prod) => {
              return prod.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      searchMDO(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredMod = [...this.mods];
          } else {
            this.filteredMod = this.mods.filter((prod) => {
              return prod.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      addItem() {
        this.items.push({
          id: this.selectedProduct.id,
          name: this.selectedProduct.name,
          qty: this.qtyToAdd,
          valor: this.valorToAdd,
          mp: this.mpnameToAdd,
          plane: this.selectedProduct.plane,
          plane_rev: this.selectedProduct.plane_rev,
          plane_number: this.selectedProduct.plane_number,
          code: this.selectedProduct.code,
          piece_formula: this.selectedProduct.formula.id,
          //sum all formula.materials.valor
          matCost: this.selectedProduct.formula.materiales.reduce(
            (a, b) => a + Number (b.valor),
            0
          ),
        });
  
        this.newItem = null;
        this.qtyToAdd = 1;
        this.valorToAdd = 1;
        this.mpnameToAdd = "";
  
        this.selectedProduct = null;
      },
      save() {
        this.submitted = true;
  
        let formData = new FormData();
  
        formData.append("id", this.form.id);

        if (this.selectedClient == null) {
          this.$toast.add({
            severity: "error",
  
            //summary: "Error!",
            summary: "Debe seleccionar un cliente",
            life: 3000,
          });
          return;
        } else {
          formData.append("cliente", this.selectedClient.id);
        }
        formData.append("dolar_value", this.p_dolar);
  
        
        if(this.form.mat_a_cargo == 0){
          formData.append("mat_a_cargo", this.form.mat_a_cargo);
        }else{
          formData.append("mat_a_cargo", this.form.mat_a_cargo_obs);
        }
  
        formData.append("observacion", this.form.observacion);
  
  
        let fecha_entrega = this.form.entrega;
        // parse to dd-mm-yy
  
        if(fecha_entrega){
  
          let fecha_entrega_ddmmyy = fecha_entrega.getDate() + "-" + (fecha_entrega.getMonth() + 1) + "-" + fecha_entrega.getFullYear();
          formData.append("fecha_entrega", fecha_entrega_ddmmyy);
        }
  
        if(this.selectedFdp){
          formData.append("forma_pago", this.selectedFdp);
        }
  
        // // parse date for php
        // let date = new Date(this.form.validez);
        // let day = date.getDate();
        // let month = date.getMonth() + 1;
        // let year = date.getFullYear();
        // let validez = year + "-" + month + "-" + day;
        formData.append("validez", this.form.validez);
  
        formData.append("moneda", this.form.moneda.value);
        formData.append("impuesto", this.form.impuesto.value);
        if(this.form.descuento){
  
          formData.append("descuento", this.form.descuento);
        }
        formData.append("envio", this.form.envio.value);
  
        formData.append("items", JSON.stringify(this.items));

        console.log(formData)

        this.form.client_id = this.selectedClient.id;
  
        CrudService.updateCRUD("api", "cotizacion", this.form).then((data) => {
          if (data.status == "success") {
            this.$toast.add({
              severity: "success",
              summary: "Éxito!",
              detail: "Actualizada correctamente.",
              life: 3000,
            });
  
            this.$router.push("/cotizaciones");
          } else {
            console.log(data);
            // forech error.data.errors
            for (var key in data.data.errors) {
              if (data.data.errors.hasOwnProperty(key)) {
                this.$toast.add({
                  severity: "error",
                  //summary: "Error!",
                  summary: data.data.errors[key][0],
                  life: 3000,
                });
              }
            }
          }
  
          // this.validationErrors = [];
          // //   if (data.status == 400) {
          // //     this.displayErrors(data.data);
          // //   } else {
          // this.$toast.add({
          //   severity: "success",
          //   summary: "Éxito!",
          //   detail: "Creada correctamente.",
          //   life: 3000,
          // });
  
          // }
        });
      },
    },
  };
  </script>
  
  
  <style scoped>
  .section_subtitle {
    font-size: 1.2rem;
    font-weight: bold;
    color: #1261b1;
    margin-bottom: 1rem;
  }
  
  .card {
    padding-top: 0.15rem;
  }
  </style>